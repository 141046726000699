import m from "mithril";
import FormUtilities from "../../shared/utilities/form_utilities";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import ModalManager from "../../shared/modal";
import Toasts from "../../shared/toasts";
import i18n from "../../shared/i18n/i18n";

let valid = true;
let userId, callback, saving, email, modalId;
const eLearningEmail = 'eLearningrequest@pegasus.net.au';

function saveEmail() {
    if (!valid) {
        Toasts.add("error", i18n.t("cant_save"), i18n.t("enter_valid_email"));
        return;
    }

    saving = true;
    Requester.post(Gateway.admin + "users/" + userId + "/email", {email: email})
        .then(function () {
            ModalManager.close(modalId);
            Toasts.add("success", i18n.t("email_updated"), "");
            if (callback) {
                callback();
            }
        }).catch(function (error) {
        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
    }).finally(function () {
        saving = false;
    });
}

const Modal_User_Edit_Email = {
    oninit: function(vnode) {
        userId = vnode.attrs.userId;
        callback = vnode.attrs.callback;
        email = vnode.attrs.email;
    },
    view: function () {
        return [
            m(".form-section  text-center", [
                m(".text-bold", i18n.t("important")),
                m("br"),
                m(".text-italic", i18n.t("edit_email_new_warning")),
                m("br"),
            ]),
            m(".form-section", [
                m(".form-label", i18n.t("email")),
                m("input[type=text]", {
                    oninput: function () {
                        email = this.value;
                        valid = FormUtilities.validateEmail(this.value);
                    },
                    value: email
                })
            ]),
            m(".form-buttons", [
                saving ? m("button.btn-text no-click", [i18n.t("saving"), m(".loading-dots")])
                : m("button", {onclick: saveEmail}, i18n.t("save"))
            ])
        ];
    },
    setModalId: function(id) {
        modalId = id;
    }
};
export default Modal_User_Edit_Email;
